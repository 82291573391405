import React, { useEffect, useState } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import "moment/locale/nl";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";

const localizer = momentLocalizer(moment);

interface Event {
  title: string;
  start: Date;
  end: Date;
}

const Cal: React.FC = () => {
  const [screenHeight, setScreenHeight] = useState(window.innerHeight * 0.85);
  const [events, setEvents] = useState<Event[]>([]);

  useEffect(() => {
    const fetchCalendar = async () => {
      try {
        const response = await fetch(
          "https://www.googleapis.com/calendar/v3/calendars/destuivers81@gmail.com/events?key=AIzaSyCova6BvlPbtNaFutv3Z2sxrABJC0OEvZU"
        );
        if (!response.ok) {
          throw new Error("Failed to fetch calendar");
        }
        const { items } = await response.json();

        const fetchedEvents: Event[] = items.map((event: any) => {
          const start = new Date(event.start.dateTime || event.start.date);
          const end = new Date(event.end.dateTime || event.end.date);

          // Adjust end date by subtracting one day
          end.setDate(end.getDate() - 1);

          return {
            title: event.summary,
            start,
            end,
          };
        });

        setEvents(fetchedEvents);
      } catch (error) {
        console.error("Error fetching calendar:", error);
      }
    };

    fetchCalendar();
  }, []);

  useEffect(() => {
    const updateHeight = () => {
      setScreenHeight(window.innerHeight * 0.5);
    };

    window.addEventListener("resize", updateHeight);

    return () => {
      window.removeEventListener("resize", updateHeight);
    };
  }, []);

  return (
    <>
      <div
        style={{
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
        }}
      >
        <br />
        <Calendar
          messages={{
            next: "Volgende",
            yesterday: "Gisteren",
            previous: "Vorige",
            today: "Vandaag",
            tomorrow: "Morgen",
            month: "Maand",
            day:'Dag'

          }}
          localizer={localizer}
          events={events}
          startAccessor="start"
          endAccessor="end"
          style={{ height: screenHeight, width: window.innerWidth * 0.85 }}
        />
      </div>
    </>
  );
};

export default Cal;
