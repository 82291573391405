import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

interface ImageCarouselProps {
  images: string[];
  overlayText: string;
}

const ImageCarousel: React.FC<ImageCarouselProps> = ({ images, overlayText }) => {
  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: false, // Keep autoplay even when hovering
    centerMode: true,
    centerPadding: "0",
  };

  return (
    <div className="carousel-container">
      {/* Overlay Text */}
      {/* <div className="carousel-overlay">{overlayText}</div> */}

      {/* Slider */}
      <Slider {...settings}>
        {images.map((src, index) => (
          <div key={index} className="slide-container">
            <img src={src} alt={`Slide ${index + 1}`} className="carousel-image" />
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default ImageCarousel;
