import { praesidium } from "../typings/types";
import PsCard from "./praesidium/psCard";

function Praesidium() {
    return (
      <main className="page-content">
        {praesidium.map((member) => (
          <PsCard key={member.id} member={member} />
        ))}
      </main>
    );
  }

  export default Praesidium