import Flipbook from "./flipbook/flipbook";
import Praesidium from "./Praesidium";
import StuiversBlad from "./StuiversBlad";

function Home() {
  return (
    <>
      <section id="about">
        <div className="container">
          <div className="row">
            <h1>Over ons</h1>
            <div className="block"></div>
            <p>
            De Stuivers is dé studentenvereniging van Antwerpen voor de studenten verpleegkunde, vroedkunde, ergotherapie en toegepaste psychologie aan de Artesis Plantijn Hogeschool met als stamcafé ‘T vervolg op de Melkmarkt. 
            </p>
          </div>

        </div>
      </section>

      <section id="team">
        <div className="container">
          <div className="row">
            <h1>Meet het Praesidium</h1>
            <div className="block"></div>

          </div>
          <Praesidium></Praesidium>
         </div>
      </section>

      <div>
        {/* <Flipbook></Flipbook> */}
      </div>

      <section id="portfolio">
        <div></div>
        {/* <h1>Stuiversblad</h1>
        <div style={{height: "500px", width: "50%"}}> */}
        {/* <embed src= "/assets/pdf/STUIVERSBLADAugustus.pdf" width= "100%" height= "100%"></embed> */}

        {/* </div> */}

        {/* <StuiversBlad></StuiversBlad> */}
      </section>

      {/* <section id="testimonial">
    <div className="container">
      <div className="quoteLoop">
        <blockquote className="quote"> <img src="http://placehold.it/100x100" width="100" height="100" alt=""/>
          <h5>&nbsp;<br>
            &rdquo;Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.&rdquo;<br>
            <small>Steve Bruce, Sed ut perspiciatis unde omnis</small></h5>
        </blockquote>
        <blockquote className="quote"> <img src="http://placehold.it/100x100" width="100" height="100" alt=""/>
          <h5>&nbsp;<br>
            &ldquo;Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam.&rdquo;<br>
            <small>Tom Jones, Sed ut perspiciatis unde omnis</small></h5>
        </blockquote>
      </div>
    </div>
  </section> */}

      {/* <section id="contact">
  <div className="container">
    <h1>Contact</h1>
    <div className="block"></div>
    <form>
      <div className="row">
        <div className="six columns">
          <label for="exampleRecipientInput">Name</label>
          <input className="u-full-width" type="text">
        </div>
        <div className="six columns">
          <label for="exampleEmailInput">Email</label>
          <input className="u-full-width" type="email">
        </div>
      </div>
      <div className="row">
        <label for="exampleMessage">Message</label>
        <textarea className="u-full-width"></textarea>
        <input className="button-primary" type="submit" value="Submit">
      </div>
    </form>
  </div>
</section> */}

    </>
  );
}

export default Home;
