import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";

// Define types for props
interface Sponsor {
  src: string;
  alt: string;
  name: string;
  description: string;
  destination: string;
}

interface GalleryProps {
  items: Sponsor[];
  setIndex: (index: number) => void;
}

interface DetailedViewProps {
  sponsor: Sponsor;
  onClose: () => void;
}

// Gallery Component
function Gallery({ items, setIndex }: GalleryProps) {
  return (
    <ul className="gallery-container">
      {items.map((item, i) => (
        <motion.li
          className="gallery-item"
          key={item.src}
          onClick={() => setIndex(i)} // Ensure the index passed is correct
          layoutId={`item-${i}`} // Ensure unique layoutId per item
        >
          <img src={item.src} alt={item.alt} className="gallery-image" />
        </motion.li>
      ))}
    </ul>
  );
}

function SmallGallery({
  items,
}: {
  items: { src: string; alt: string; destination: string }[];
}) {
  return (
    <ul className="small-gallery-container">
      {items.map((item, i) => (
        <li className="small-gallery-item" key={i}>
          <a href={item.destination}>
            <img
              src={item.src}
              alt={item.alt}
              className="small-gallery-image"
            />
          </a>
        </li>
      ))}
    </ul>
  );
}

// Detailed View Component
function DetailedView({ sponsor, onClose }: DetailedViewProps) {
  const handleOverlayClick = (event: React.MouseEvent) => {
    onClose(); // Close the detailed view when the overlay is clicked
  };

  const handleContainerClick = (event: React.MouseEvent) => {
    event.stopPropagation(); // Prevent the click from bubbling up to the overlay
  };

  return (
    <div className="overlay" onClick={handleOverlayClick}>
      <div className="single-image-container" onClick={handleContainerClick}>
        <motion.div
          layoutId={`item-${sponsor.src}`} // Ensure unique layoutId for detailed view as well
          className="detailed-view-item"
        >
          <a href={sponsor.destination}>
            <img
              src={sponsor.src}
              alt={sponsor.alt}
              className="detailed-view-image"
            />
          </a>

          <div className="detailed-view-details">
            <h2>{sponsor.name}</h2>
            <p>{sponsor.description}</p>
            <button className="close-button" onClick={onClose}>
              Close
            </button>
          </div>
        </motion.div>
      </div>
    </div>
  );
}

// Main Sponsors Component
export default function Sponsors() {
  const [selectedItem, setSelectedItem] = useState<number | null>(null);

  const bigSponsors: Sponsor[] = [
    {
      src: "assets/images/sponsors/Acerta.png",
      alt: "Acerta",
      name: "Acerta",
      description:
        "Sta je op het punt om zelfstandig te worden? Als ondernemingsloket en sociaal verzekeringsfonds ondersteunen we jou bij je inschrijving in de KBO en helpen we je bij het verkrijgen van je ondernemingsnummer. Bovendien zorgen we voor je aansluiting bij ons sociaal verzekeringsfonds, waardoor je toegang krijgt tot sociale voordelen zoals pensioen en mantelzorg. Bij ons zit je dus goed! Jij volgt je dromen, wij regelen de administratieve formaliteiten vlot in één keer.",
      destination: "https://www.acerta.be/nl/starters",
    },
    {
      src: "assets/images/sponsors/Clickcare.png",
      alt: "Clickcare",
      name: "Clickcare",
      description:
        "ClickCare is een dynamisch bedrijf dat zich richt op het verbeteren van de communicatie en samenwerking in de zorg. Ze bieden digitale oplossingen die zorgprofessionals helpen om informatie eenvoudig en veilig uit te wisselen. Met hun gebruiksvriendelijke platformen kunnen zorgverleners efficiënter samenwerken, wat leidt tot betere zorg voor patiënten. ClickCare staat bekend om zijn innovatieve aanpak en betrokkenheid bij het optimaliseren van zorgprocessen!",
      destination: "https://clickcare.be/",
    },
    {
      src: "assets/images/sponsors/ZAS.png",
      alt: "ZAS",
      name: "ZAS",
      description:
        "ZAS is de grootste gezondheidsorganisatie van België, met 13 campussen in Antwerpen. Jaarlijks verzorgen 10.000 medewerkers, waaronder 1.000 artsen, 1,4 miljoen raadplegingen en 8.500 bevallingen. Ontstaan uit de fusie van ZNA en GZA, biedt ZAS warme, kwalitatieve zorg en een werkplek met kansen voor groei en innovatie.",
      destination: "https://www.zas.be/jobs",
    },
    {
      src: "assets/images/sponsors/X-care.png",
      alt: "X-care",
      name: "X-care",
      description:
        "X-Care in Motion is dé HR expert in Flexibel & Temporary Staffing met focus op de zorg. Ze zijn een trotse tak van de Planet Group, een gedreven onderneming gespecialiseerd in HR-dienstverlening. Enkele van onze zusterbedrijven zijn onder andere HR-Planet & IT-Planet. Ze zijn al enkele jaren op rij uitgeroepen tot Great Place To Work! Bij hen kan je terecht voor een job als projectmedewerker (vast in dienst), freelancer en als uitzendkracht (interim & jobstudent).",
      destination: "https://x-careinmotion.be/vacatures/",
    },
    {
      src: "assets/images/sponsors/UZA.png",
      alt: "UZA",
      name: "UZA",
      description:
        "UZA, het Universitair Ziekenhuis Antwerpen, is een vooraanstaand ziekenhuis dat gespecialiseerd is in topklinische zorg, onderwijs en onderzoek. Het ziekenhuis biedt een breed scala aan medische diensten en beschikt over moderne faciliteiten en technologie. UZA staat bekend om zijn hoogwaardige zorg en betrokkenheid bij patiënten, met een team van ervaren professionals dat zich inzet voor optimale behandelingen. Daarnaast speelt het ziekenhuis een belangrijke rol in de opleiding van toekomstige zorgprofessionals en wetenschappelijk onderzoek, wat bijdraagt aan innovatie in de gezondheidszorg.",
      destination: "https://www.uza.be/nl",
    },
    {
      src: "assets/images/sponsors/WitGeel.png",
      alt: "WitGeel",
      name: "WitGeel",
      description:
        "Het Wit-Gele Kruis van Antwerpen is een belangrijke organisatie die thuiszorg en verpleegkundige diensten aanbiedt aan mensen in de regio. Met een team van ervaren verpleegkundigen en zorgverleners biedt de organisatie ondersteuning aan patiënten in hun eigen omgeving, met een focus op persoonlijke zorg en aandacht. Ze zijn er voor diverse zorgvragen, van wondverzorging tot begeleiding bij chronische aandoeningen. Het Wit-Gele Kruis streeft ernaar om de kwaliteit van leven van hun cliënten te verbeteren, met respect voor hun wensen en behoeften.",
      destination: "https://www.witgelekruis.be/",
    },
  ];

  const smalSponsors: Sponsor[] = [
    {
      src: "assets/images/sponsors/Altrio.png",
      alt: "Acerta",
      name: "Acerta",
      description:
        "Jouw zorgspecialisten én gelukbrengers. In heel Vlaanderen, voor elk type thuiszorg en voor iedere zorgbehoevende bovendien dicht bij. Want nabijheid en betrokkenheid maken wel degelijk het verschil. Wij zijn een beweging van gelukbrengers, laat ons geluk brengen aan iedereen rondom ons. Want positief verbinden geeft goesting in het leven en maakt iedereen wat beter.",
      destination: "https://altrio.be/vacatures/",
    },
    {
      src: "assets/images/sponsors/Chipsoft.png",
      alt: "Chipsoft",
      name: "Chipsoft",
      description: "Sponsor 2 provides sustainable solutions for businesses.",
      destination: "https://www.chipsoft.com/nl-BE/werken-bij",
    },
    {
      src: "assets/images/sponsors/In't-Boerke.png",
      alt: "In't-Boerke",
      name: "In't-Boerke",
      description: "Sponsor 3 is committed to making the world a better place.",
      destination: "https://www.intboerke.be/",
    },
    {
      src: "assets/images/sponsors/8tea5.jpg",
      alt: "8tea5",
      name: "8tea5",
      description: "Sponsor 4 is revolutionizing the tech industry with AI.",
      destination: "https://8tea5.com/",
    },
    {
      src: "assets/images/sponsors/Bijleshuis.png",
      alt: "Bijleshuis",
      name: "Bijleshuis",
      description: "Sponsor 4 is revolutionizing the tech industry with AI.",
      destination: "https://bijleshuis.be/",
    },
    {
      src: "assets/images/sponsors/Carrefour%20Market.png",
      alt: "Carrefour Market",
      name: "Carrefour Market",
      description: "Sponsor 4 is revolutionizing the tech industry with AI.",
      destination: "https://www.carrefour.be/",
    },
    {
      src: "assets/images/sponsors/Colruyt.png",
      alt: "Colruyt",
      name: "Colruyt",
      description: "Sponsor 4 is revolutionizing the tech industry with AI.",
      destination: "https://www.colruyt.be/",
    },
    {
      src: "assets/images/sponsors/Studant.png",
      alt: "Studant",
      name: "Studant",
      description: "Sponsor 4 is revolutionizing the tech industry with AI.",
      destination: "https://www.studant.be/",
    },
    {
      src: "assets/images/sponsors/Universitas.png",
      alt: "Universitas",
      name: "Universitas",
      description: "Sponsor 4 is revolutionizing the tech industry with AI.",
      destination: "https://www.universitas.be/",
    },
  ];

  const handleSelect = (index: number) => {
    setSelectedItem(index);
  };

  const handleClose = () => {
    setSelectedItem(null);
  };

  return (
    <>
      <div>
        <div className="Sponsors container">
          <div className="row">
            <h1>Sponsors</h1>
            <div className="block"></div>
            <p>De Stuivers werken samen met de volgende geweldige sponsors</p>
          </div>
        </div>
      </div>
      <Gallery items={bigSponsors} setIndex={handleSelect} />
      <AnimatePresence>
        {selectedItem !== null && (
          <DetailedView
            sponsor={bigSponsors[selectedItem]}
            onClose={handleClose}
          />
        )}
      </AnimatePresence>
      <br />
      <SmallGallery items={smalSponsors} />
    </>
  );
}
