import { Col, Container, Row } from "react-bootstrap";

function Footer() {
  return (
    <>
<footer className="site-footer">
        
      <div className="container">
        <div className="row">
          <div className="col-md-8 col-sm-6 col-xs-12">
            <p className="copyright-text">Copyright &copy; 2024 All Rights Reserved by DeStuivers.
            </p>
          </div>

          <div className="col-md-4 col-sm-6 col-xs-12">
            <ul className="social-icons">
              <li><a className="facebook" href="https://www.facebook.com/destuivers"><i className="bi bi-facebook"></i></a></li>
              <li><a className="instagram" href="https://www.instagram.com/destuivers/"><i className="bi bi-instagram"></i></a></li>
              <li><a className="issuu" href="https://issuu.com/scriptordestuivers"><i className="bi bi-newspaper"></i></a></li>
            </ul>
          </div>
        </div>
      </div>
</footer>
    </>
  );
}

export default Footer;
