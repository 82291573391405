import React, { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import Layout from "./components/Layout";
import Home from "./components/Home";
import Cal from "./components/Calendar";
import Clublied from "./components/Clublied";
import Praesidium from "./components/Praesidium";
import Sponsors from "./components/Sponsors";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";

// Component for External Redirect
const ExternalRedirect = ({ url }: { url: string }) => {
  useEffect(() => {
    window.location.href = url;
  }, [url]);

  return null; // Component doesn't render anything
};

function App() {
  return (
    <div className="App">
      <div>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            <Route path="calendar" element={<Cal />} />
            <Route path="clublied" element={<Clublied />} />
            <Route path="praesidium" element={<Praesidium />} />
            <Route path="sponsors" element={<Sponsors />} />
            <Route
              path="lid worden"
              element={<ExternalRedirect url="https://lid.destuivers.be" />}
            />
          </Route>
        </Routes>
      </div>
    </div>
  );
}

export default App;
