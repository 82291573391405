export type PraesidiumMember = {
  id: number;
  Naam: string;
  Bijnaam: string;
  Afbeelding: string;
  Leeftijd: number;
  Functie: string;
  Opleiding: string;
};

export const praesidium: PraesidiumMember[] = [
  {
    id: 1,
    Naam: "Zoë",
    Bijnaam: "De bomma",
    Afbeelding: "/assets/images/Zoe.jpg",
    Leeftijd: 26,
    Functie: "Praeses",
    Opleiding: "Integrale Veiligheid",
  },
  {
    id: 8,
    Naam: "Chalonna",
    Bijnaam: "Lona",
    Afbeelding: "/assets/images/Challona.jpg",
    Leeftijd: 20,
    Functie: "Vice-Praeses & Zedenmeester",
    Opleiding: "Toegepaste Psychologie",
  },
  {
    id: 3,
    Naam: "Jana",
    Bijnaam: "Yaan",
    Afbeelding: "/assets/images/Yana.jpg",
    Leeftijd: 21,
    Functie: "Quaestor",
    Opleiding: "Toegepaste Psychologie",
  },
  {
    id: 5,
    Naam: "Len",
    Bijnaam: "Juppy",
    Afbeelding: "/assets/images/Len.jpg",
    Leeftijd: 22,
    Functie: "Schachtenmeester",
    Opleiding: "Elector Mechanische Systemen",
  },
  {
    id: 6,
    Naam: "Emma",
    Bijnaam: "Sparky",
    Afbeelding: "/assets/images/Emma.jpg",
    Leeftijd: 20,
    Functie: "Schachtentemmer",
    Opleiding: "Toegepaste Psychologie",
  },
  {
    id: 7,
    Naam: "Dylan",
    Bijnaam: "Dokus",
    Afbeelding: "/assets/images/Dylan.jpg",
    Leeftijd: 24,
    Functie: "Schachtentemmer & Web",
    Opleiding: "/",
  },

  {
    id: 9,
    Naam: "Anaïs",
    Bijnaam: "Anijs",
    Afbeelding: "/assets/images/Anais.jpg",
    Leeftijd: 19,
    Functie: "Zedenmeester",
    Opleiding: "Toegepaste Psychologie",
  },
  {
    id: 10,
    Naam: "Leen",
    Bijnaam: "Loen",
    Afbeelding: "/assets/images/Leen.jpg",
    Leeftijd: 23,
    Functie: "Feestleider",
    Opleiding: "Schoonheidsspecialiste",
  },
  {
    id: 11,
    Naam: "Oceane",
    Bijnaam: "Oce",
    Afbeelding: "/assets/images/Oce.jpg",
    Leeftijd: 20,
    Functie: "Feestleider",
    Opleiding: "Vroedkunde",
  },
  {
    id: 12,
    Naam: "Joke",
    Bijnaam: "/",
    Afbeelding: "/assets/images/Joke.jpg",
    Leeftijd: 25,
    Functie: "Dux-festi",
    Opleiding: "/",
  },
  {
    id: 13,
    Naam: "Marie-Elise",
    Bijnaam: "De Waal",
    Afbeelding: "/assets/images/Marie.jpg",
    Leeftijd: 24,
    Functie: "Dux-festi",
    Opleiding: "Master of Science Verpleegkunde en Vroedkunde",
  },
  // {
  //   id: 14,
  //   Naam: "Hilde",
  //   Bijnaam: "/",
  //   Afbeelding: "/assets/images/Hilde.jpg",
  //   Leeftijd: 23,
  //   Functie: "Cantor",
  //   Opleiding: "/",
  // },
  {
    id: 16,
    Naam: "Robbe",
    Bijnaam: "FapSoc",
    Afbeelding: "/assets/images/Robbe.jpg",
    Leeftijd: 19,
    Functie: "S.O.C.",
    Opleiding: "Toegepaste Psychologie",
  },
  {
    id: 17,
    Naam: "Jara",
    Bijnaam: "Reserve Belg",
    Afbeelding: "/assets/images/Jara.jpg",
    Leeftijd: 24,
    Functie: "Scriptor",
    Opleiding: "Verpleegkunde & Vroedkunde",
  },
  // {
  //   id: 18,
  //   Naam: "Tedah",
  //   Bijnaam: "Theetje",
  //   Afbeelding: "/assets/images/Tedah.jpg",
  //   Leeftijd: 21,
  //   Functie: "Media",
  //   Opleiding: "Toegepaste Psychologie",
  // },
  {
    id: 19,
    Naam: "Lennert",
    Bijnaam: "Leo",
    Afbeelding: "/assets/images/Lennert.jpg",
    Leeftijd: 25,
    Functie: "Peter",
    Opleiding: "/",
  },
  {
    id: 20,
    Naam: "Steffi",
    Bijnaam: "/",
    Afbeelding: "/assets/images/Steffi.png",
    Leeftijd: 26,
    Functie: "Meter",
    Opleiding: "/",
  },
  //   {
  //     id: 21,
  //     Naam: "Thomas",
  //     Bijnaam: "/",
  //     Afbeelding: "/assets/images/Thomas.png",
  //     Leeftijd: 24,
  //     Functie: "Ere-Dux-Festi",
  //     Opleiding: "/",
  //   },
  {
    id: 22,
    Naam: "Nick",
    Bijnaam: "/",
    Afbeelding: "/assets/images/Nick.png",
    Leeftijd: 30,
    Functie: "Peter Honoris Causa",
    Opleiding: "/",
  },
  {
    id: 23,
    Naam: "Robin",
    Bijnaam: "Robijn",
    Afbeelding: "/assets/images/Robin.jpg",
    Leeftijd: 33,
    Functie: "Peter Honoris Causa",
    Opleiding: "/",
  },
];
