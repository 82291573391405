// src/components/PsCard.js
import React from 'react';
import { PraesidiumMember } from '../../typings/types'; // Adjust the import path as necessary

const PsCard = ({ member }: { member: PraesidiumMember }) => {
  return (
    <div className="card" style={{ backgroundImage: `url(${member.Afbeelding})`, backgroundSize: 'contain', backgroundPosition: 'center', backgroundRepeat: 'no-repeat'}}>
      <div className="content">
        <h2 className="title">{member.Functie}</h2>
        <p className="copy">Naam: {member.Naam}</p>
        <p className="copy">Bijnaam: {member.Bijnaam}</p>
        <p className="copy">Leeftijd: {member.Leeftijd}</p>
        <p className="copy">Opleiding: {member.Opleiding}</p>
        {/* <img src="#" alt={`${member.Naam}`} className="image" /> */}
      </div>
    </div>
  );
};

export default PsCard;
