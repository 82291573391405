function Clublied() {
  return (
    <>
      <div className="song-lyrics" style={{textAlign: "center"}}>
        <h1>De Stuivers Clublied</h1>
        <h2>Wijze: John Brown’s Body</h2>
        <p className="note">Lyrics by: Johan Van Volsem</p>

        <p>Wij studeren voor verpleegkunde,</p>
        <p className="inline">
          of doen daarvoor ons best. <span className="note"> (ter)</span>
        </p>

        <p>Maar toch zijn we soms geflest.</p>

        <br></br>
        <p className="chorus">Zwart, wit, zwart, De Stuivers,</p>
        <p className="chorus">Feesten doen wij bij De Stuivers,</p>
        <p className="chorus">Drinken dat kunnen De Stuivers.</p>
        <p className="chorus">Maar toch zijn we soms geflest.</p>

        <br></br>

        <p>Dat komt door al die cantussen</p>
        <div style={{ display: "inline-block", textAlign: "center", marginBottom:"1rem" }}>
          <p style={{ display: "inline" }}>en menig TD.</p>
          <p className="note" style={{ display: "inline" }}>
            {" "}
            (ter)
          </p>
        </div>

        <p>Want daar doen we graag aan mee.</p>

        <br></br>

        <p className="chorus">Zwart, wit, zwart, De Stuivers,</p>
        <p className="chorus">Feesten doen wij bij De Stuivers,</p>
        <p className="chorus">Drinken dat kunnen De Stuivers.</p>
        <p className="chorus">En daar doen we graag aan mee.</p>

        <br></br>

        <p>Daardoor zijn wij nu bekend in</p>
        <div style={{}}>
          <p>heel Antwerpen. </p>
          <p className="note">(ter)</p>
        </div>

        <p>En men zal ons nooit verwerpen.</p>

        <br></br>

        <p className="chorus">Zwart, wit, zwart, De Stuivers,</p>
        <p className="chorus">Feesten doen we bij De Stuivers,</p>
        <p className="chorus">Drinken dat kunnen De Stuivers,</p>
        <p className="chorus">En men zal ons nooit verwerpen.</p>

        <br></br>

        <p>Want de schachten en de porren</p>

        <div style={{}}>
          <p>vallen allemaal goed mee.</p>
          <p className="note">(ter)</p>
        </div>
        <p>En ze drinken nu al voor twee.</p>

        <br></br>

        <p className="chorus">Zwart, wit, zwart, De Stuivers,</p>
        <p className="chorus">Feesten doen we bij De Stuivers,</p>
        <p className="chorus">Drinken dat kunnen De Stuivers,</p>
        <p className="chorus">En ze drinken nu al voor twee.</p>

        <br></br>
        <p>En moeten we studeren voor partieels</p>

        <div style={{}}>
        <p>of eerste zit.</p>
        <p className="note">(ter)</p>

        </div>

        <p>Denken we nog aan zwart en wit.</p>
        <br></br>

        <p className="chorus">Zwart, wit, zwart, De Stuivers,</p>
        <p className="chorus">Feesten doen we bij De Stuivers,</p>
        <p className="chorus">Drinken dat kunnen De Stuivers.</p>
        <div style={{}}>
        <p className="chorus">En we blijven trouw aan zwart </p>
        <p className="note">(ter)</p> 
        </div>

        <p className="chorus">En wit. Zwart en wit!</p>
      </div>
    </>
  );
}

export default Clublied;
