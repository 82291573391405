import React, { useState, useEffect } from "react";
import { Col, Container, Nav, Navbar, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import ImageCarousel from "./slider/ImageCarousel";

const NavigationBar = () => {
  const images = [
    "../assets/images/image1.jpg",
    "../assets/images/image2.jpg",
    "../assets/images/image3.jpg",
  ];

  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 100); // Adjust this value as needed
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    // <Navbar
    //   expand="lg"
    //   classNameName={`navbar-custom ${isScrolled ? 'navbar-scrolled' : ''}`}
    // >
    //   <Container>
    //     <Navbar.Brand as={Link} to="/">
    //       <img
    //         src="/assets/images/schild.png"
    //         width="30"
    //         height="30"
    //         alt="Logo"
    //       />
    //     </Navbar.Brand>
    //     <Navbar.Toggle aria-controls="basic-navbar-nav" />
    //     <Navbar.Collapse id="basic-navbar-nav">
    //       <Nav classNameName="ms-auto">
    //         <Nav.Link as={Link} to="/">Home</Nav.Link>
    //         <Nav.Link as={Link} to="/calendar">Calendar</Nav.Link>
    //         <Nav.Link as={Link} to="/clublied">Clublied</Nav.Link>
    //         <Nav.Link as={Link} to="/praesidium">Praesidium</Nav.Link>
    //         <Nav.Link as={Link} to="/sponsors">Sponsors</Nav.Link>
    //         <Nav.Link href="https://lid.destuivers.be">Lid worden</Nav.Link>
    //       </Nav>
    //     </Navbar.Collapse>
    //   </Container>
    // </Navbar>
    <header id="header">
      <div className="main_nav">
        <div className="container">
          {/* <Container>
        <Row>
          <Col>
            <img
              src="/assets/images/schild.png"
              width={"20px"}
              height={"20px"}
            ></img>
          </Col>
          <Col>
            <nav>
              <ul>
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <Link to="/calendar">Calendar</Link>
                </li>
                <li>
                  <Link to="/clublied">Clublied</Link>
                </li>
                <li>
                  <Link to="/praesidium">Praesidium</Link>
                </li>
                <li>
                  <Link to="/sponsors">Sponsors</Link>
                </li>
                <li>
                  <a href="https://lid.destuivers.be">Lid worden</a>
                </li>
              </ul>
            </nav>
          </Col>
        </Row>
      </Container> */}
          {/* <div className="mobile-toggle"> <span></span> <span></span> <span></span> </div> */}
          {/* <nav>
        <ul>
          <li><a className="smoothscroll" href="#header">Home</a></li>
          <li><a className="smoothscroll" href="#about">About</a></li>
          <li><a className="smoothscroll" href="#skills">Services</a></li>
          <li><a className="smoothscroll" href="#portfolio">Work</a></li>
          <li><a className="smoothscroll" href="#contact">Contact</a></li>
        </ul>
      </nav> */}
        </div>
      </div>
      <div className="title">
        <ImageCarousel
          images={[
            "../assets/images/front/ds1.jpg",
            "../assets/images/front/ds2.jpg",
            "../assets/images/front/ds3.jpg",
            "../assets/images/front/ds4.jpg",
            "../assets/images/front/ds5.jpg",
            "../assets/images/front/ds6.jpg",
            "../assets/images/front/ds7.jpg",

          ]}
          overlayText="De Stuivers"
        />
      </div>
      <a className="smoothscroll" href="#about">
        <div className="scroll-down"></div>
      </a>{" "}
    </header>
  );
};

export default NavigationBar;
